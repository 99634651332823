//AUTO REQUEST TASK DETAILS - MESSAGES
export function autoGetActiveTaskDetailsMessages() {
	// To scroll to the bottom of a div
	const theElement = document.getElementById(
		"tasks-details-column__task-description-messages-container"
	);
	let currentScrollTop = theElement ? theElement.scrollTop : 0;

	$.ajax({
		url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
		type: "POST",
		//processData: true,
		/* contentType: false, */
		dataType: "html",
		//contentType: 'text/html',
		data: {
			action: "we_get_single_task_data",
			task_id: $("#task-details-and-comments").attr("data-task-id"),
			task_section: $('#task-details-and-comments').attr('data-task-section'),
			/* new_client_inquiry_task_name: new_client_inquiry_task_name,
                    new_client_inquiry_task_description:
                        new_client_inquiry_task_description,
                    new_client_inquiry_client_name: new_client_inquiry_client_name,
                    new_client_inquiry_client_company_name:
                        new_client_inquiry_client_company_name,
                    new_client_inquiry_client_email:
                        new_client_inquiry_client_email,
                    new_client_inquiry_client_address_with_files:
                        new_client_inquiry_client_address_with_files, */
		},
		//data: fd,
		success: function (data) {
			var response = JSON.parse(data);
			$("#task-details-and-comment-container").html(response.data);
			$(".we-loading").remove();
			$(".tasks-details-column__task-description-messages-container").attr(
				"style",
				"height: calc(100vh - 50px - 70px - 0px - 120px);"
			);

			/* $("#task-details-and-comment-container").css("display", "none");
					$("#task-details-and-comment-container").fadeIn(300); */

			// To scroll to the bottom of a div
			const theElement = document.getElementById(
				"tasks-details-column__task-description-messages-container"
			);

			const scrollToBottom = (node) => {
				console.log(currentScrollTop);
				node.scrollTop = currentScrollTop;
				//node.scrollTop = node.scrollHeight;
			};

			scrollToBottom(theElement); // The specified node scrolls to the bottom.

			$(".animated-line").remove();
			$(".task-content").children().css("display", "block");

			//MAGNIFIC POPUP
			$(".comment-image-link").magnificPopup({
				type: "image",
				// other options
			});

			$(".comment-files").magnificPopup({
				delegate: ".comment-image-link", // child items selector, by clicking on it popup will open
				type: "image",
				// other options
			});

			if (window.history.replaceState) {
				window.history.replaceState(null, null, window.location.href);
			}
		},
		error: function (data) {
			//var results = JSON.parse(data);
			$(".we-loading").remove();
		},
	});
}
