import { setTasksColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

gsap.registerPlugin(ScrollToPlugin);

$(function () {
    $("#project-generalni-upiti").on("click", function (e) {
			e.preventDefault();
			$(".projects-list__single").removeClass("active");
			$(this).addClass("active");

			var windowWidt = $(window).width();

			if (windowWidt <= 700) {
				projectCloseColumn();
			}

			$(".tasks-column").append(
				'<div class="we-loading" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:0 0" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><path fill="none" stroke="#1565C0" stroke-width="6.4" stroke-dasharray="42.765 42.765" d="M19.44 24C9.12 24 4 34.64 4 40s5.12 16 15.44 16c15.44 0 25.68-32 41.12-32C70.88 24 76 34.64 76 40s-5.12 16-15.44 16c-15.44 0-25.68-32-41.12-32z" stroke-linecap="round" style="transform-origin:50px 50px"><animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"/></path></svg></div></div>'
			);

			$.ajax({
				url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
				type: "POST",
				//processData: true,
				/* contentType: false, */
				dataType: "html",
				//contentType: 'text/html',
				data: {
					action: "get_generalni_upiti_tasks",
					security: ajax_object.ajax_nonce,
					/* new_client_inquiry_task_name: new_client_inquiry_task_name,
                    new_client_inquiry_task_description:
                        new_client_inquiry_task_description,
                    new_client_inquiry_client_name: new_client_inquiry_client_name,
                    new_client_inquiry_client_company_name:
                        new_client_inquiry_client_company_name,
                    new_client_inquiry_client_email:
                        new_client_inquiry_client_email,
                    new_client_inquiry_client_address_with_files:
                        new_client_inquiry_client_address_with_files, */
				},
				//data: fd,
				success: function (data) {
					var response = JSON.parse(data);
					console.log("ajax done succesful");
					console.log(response.data);
					$(".tasks__group-tasks-container").html(response.data);
					setTasksColumnWidth();
					$(".we-loading").remove();
					$(".tasks__group:first-of-type .tasks__single:first-of-type").trigger(
						"click"
					);

					if (window.history.replaceState) {
						window.history.replaceState(null, null, window.location.href);
					}
				},
				error: function (data) {
					//var results = JSON.parse(data);
					console.log("ajax error");
					console.log(data);
					$(".we-loading").remove();
					Swal.fire({
						position: "center-center",
						icon: "error",
						showCloseButton: true,
						title: "Ups!",
						text: "",
						showConfirmButton: false,
						html: "<p>UPS! Nešto je negdje zapelo. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
						//willClose: redirectToTasks,
					});
					if (window.history.replaceState) {
						window.history.replaceState(null, null, window.location.href);
					}
				},
			});
		});
});