/* Import TinyMCE */
import 'tinymce/tinymce';


tinymce.init({
	selector: "textarea#we-new-comment",
	height: '50px',
	/* width: "80%", */
	menubar: false,
	statusbar: false,
	contextmenu: false,
	toolbar: false, //false, //"insertfile a11ycheck | link image emoticons image",
	toolbar_location: "bottom",
	image_dimensions: false,
	plugins: [
		"autoresize",
		"advlist",
		"autolink",
		"lists",
		"link",
		/* "image", */
		"charmap",
		"preview",
		"anchor",
		"searchreplace",
		"visualblocks",
		"code",
		"fullscreen",
		"insertdatetime",
		/* "media", */
		"table",
		"code",
		"help",
		"wordcount",
		"emoticons",
	],
	setup: function (editor) {
		editor.on("focus", function (e) {
			$(".tox-tinymce").attr("style", "height: 150px !important;");
			//$(".tox:not(.tox-tinymce-inline) .tox-editor-header").show(400);
		});
		editor.on("blur", function (e) {
			$(".tox-tinymce").attr("style", "height: 50px !important;");
			//$(".tox:not(.tox-tinymce-inline) .tox-editor-header").hide(400);
		});
	},
/* 	images_upload_url: "https://zagrebweb.hr/tinymce-image-upload-handler.php",
	automatic_uploads: true,
	images_upoload_handler: function (blobInfo, success, failure) {
		var xhr, formData;

		xhr = new XMLHttpRequest();
		xhr.withCredentials = false;
		xhr.open("POST", "https://zagrebweb.hr/tinymce-image-upload-handler.php");

		xhr.upload.onprogress = function (e) {
			progress((e.loaded / e.total) * 100);
		};

		xhr.onload = function () {
			var json;

			if (xhr.status === 403) {
				failure("HTTP Error: " + xhr.status, { remove: true });
				return;
			}

			if (xhr.status < 200 || xhr.status >= 300) {
				failure("HTTP Error: " + xhr.status);
				return;
			}

			json = JSON.parse(xhr.responseText);

			if (!json || typeof json.location != "string") {
				failure("Invalid JSON: " + xhr.responseText);
				return;
			}

			success(json.location);
		};

		xhr.onerror = function () {
			failure(
				"Image upload failed due to a XHR Transport error. Code: " + xhr.status
			);
		};

		formData = new FormData();
		formData.append("file", blobInfo.blob(), blobInfo.filename());

		xhr.send(formData);
	}, */
});