import { setTasksColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

import "magnific-popup";

import {autoGetActiveTaskDetailsMessages} from '../functions';

gsap.registerPlugin(ScrollToPlugin);


$(function () {
	$("body").on(
		"click",
		".tasks__single .column-2-text-notification-icons-container, .tasks__single .tasks__single-we-task-number, .tasks__single .tasks__single-we-task-status, .tasks__single .progress-animation",
		function (e) {
			e.preventDefault();

			var self = $(this).closest(".tasks__single");
			$(".tasks__single").removeClass("active");
			var taskSection = self.attr('data-task-section');
			self.addClass("active");

			$(".task-content").children().not(".animated-line").css('display', 'none');
			$('<div class="animated-line"></div>').appendTo(".task-content");

			$(".tasks-details-column__inner").append(
				'<div class="we-loading" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:0 0" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" r="0" fill="none" stroke="#0d47a1" stroke-width="2"><animate attributeName="r" repeatCount="indefinite" dur="2s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="0s"/><animate attributeName="opacity" repeatCount="indefinite" dur="2s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="0s"/></circle><circle cx="50" cy="50" r="0" fill="none" stroke="#1565c0" stroke-width="2"><animate attributeName="r" repeatCount="indefinite" dur="2s" values="0;40" keyTimes="0;1" keySplines="0 0.2 0.8 1" calcMode="spline" begin="-1s"/><animate attributeName="opacity" repeatCount="indefinite" dur="2s" values="1;0" keyTimes="0;1" keySplines="0.2 0 0.8 1" calcMode="spline" begin="-1s"/></circle></svg></div></div>'
			);

			$.ajax({
				url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
				type: "POST",
				//processData: true,
				/* contentType: false, */
				dataType: "html",
				//contentType: 'text/html',
				data: {
					action: "we_get_single_task_data",
					task_id: self.data("id"),
					task_section: taskSection,
					security: ajax_object.ajax_nonce,
					/* new_client_inquiry_task_name: new_client_inquiry_task_name,
                    new_client_inquiry_task_description:
                        new_client_inquiry_task_description,
                    new_client_inquiry_client_name: new_client_inquiry_client_name,
                    new_client_inquiry_client_company_name:
                        new_client_inquiry_client_company_name,
                    new_client_inquiry_client_email:
                        new_client_inquiry_client_email,
                    new_client_inquiry_client_address_with_files:
                        new_client_inquiry_client_address_with_files, */
				},
				//data: fd,
				success: function (data) {
					var response = JSON.parse(data);
					$("#task-details-and-comment-container").html(response.data);
					$(".we-loading").remove();

					//HIDE NEW MESSAGE FORM FOR SPECIFIC SECTIONS
					if (
						$(".projects__task-detail-column #task-details-and-comments").attr(
							"data-task-section"
						) == "Revizija" ||
						$(".projects__task-detail-column #task-details-and-comments").attr(
							"data-task-section"
						) == "Odrađeno"
					) {
						$(".task-new-message-container").css("display", "none");
					} else {
						$(".task-new-message-container").css("display", "block");
					}
					//
					$(".tasks-details-column__task-description-messages-container").attr(
						"style",
						"height: calc(100vh - 50px - 70px - 0px - 120px);"
					);

					$("#task-details-and-comment-container").css("display", "none");
					$("#task-details-and-comment-container").fadeIn(300);

					$(".animated-line").remove();
					$(".task-content").children().css("display", "block");

					//MAGNIFIC POPUP
					$(".comment-image-link").magnificPopup({
						type: "image",
						// other options
					});

					$(".comment-files").magnificPopup({
						delegate: ".comment-image-link", // child items selector, by clicking on it popup will open
						type: "image",
						// other options
					});

					if (window.history.replaceState) {
						window.history.replaceState(null, null, window.location.href);
					}
				},
				error: function (data) {
					//var results = JSON.parse(data);
					$(".we-loading").remove();
					Swal.fire({
						position: "center-center",
						icon: "error",
						showCloseButton: true,
						title: "Ups!",
						text: "",
						showConfirmButton: false,
						html: "<p>UPS! Nešto je negdje zapelo. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
						//willClose: redirectToTasks,
					});
					if (window.history.replaceState) {
						window.history.replaceState(null, null, window.location.href);
					}
				},
			});

			tinyMCE.activeEditor.setContent("");
		}
	);


setInterval(autoGetActiveTaskDetailsMessages, 20000);


});
