import { setTasksColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

import { autoGetActiveTaskDetailsMessages } from "../functions";

import * as FilePond from "filepond";
import "filepond/dist/filepond.min.css";

// Import the plugin code
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Import the plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Import the File Type Validation plugin
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

$(function () {
	if ($(".page_board").length > 0) {
		// Register the plugin
		FilePond.registerPlugin(FilePondPluginImagePreview);
	FilePond.registerPlugin(FilePondPluginFileValidateType);

	//MESSAGE UPLOAD FILEPOND
	// Get a reference to the file input element
	const inputElement = document.querySelector("#filepond-task-chat");

	// Create a FilePond instance
	const pond = FilePond.create(inputElement, {
		// Only accept images
		credits: false,
		//dropOnPage: true,

		name: "filepond",
		maxFiles: 5,
		allowBrowse: true,
		acceptedFileTypes: ["image/*", "application/pdf"],
		//storeAsFile: true,
		allowMultiple: true,
		instantUpload: false,
		allowProcess: false,
		// server
	});

	document
		.querySelector(".custom-file-upload-btn")
		.addEventListener("click", () => {
			pond.browse();
		});

	gsap.registerPlugin(ScrollToPlugin);

	//NEW COMMENT POST

	$("#upiti-new-comment-form").on("submit", function (e) {
		e.preventDefault();

		var self = $(this);
		var newCommentText = $("#we-new-comment").val();

		var fd = new FormData(this);
		// append files array into the form data
		var pondFiles = pond.getFiles();
		for (var i = 0; i < pondFiles.length; i++) {
			fd.append("filepond[]", pondFiles[i].file);
		}

		fd.append("task_id", $("#task-details-and-comments").data("task-id"));
		fd.append("new_comment_content", newCommentText);
		fd.append("action", "we_post_new_comment");
		fd.append("security", ajax_object.ajax_nonce);

		//FILES

		$("#we-new-comment").val("");
		var preNewCommentTaskHtml = $("#task-details-and-comment-container").html();

		tinyMCE.activeEditor.setContent("");
		pond.removeFiles();

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			processData: false,
			contentType: false,
			cache: false,
			dataType: "html",
			//contentType: 'text/html',
			/* 			data: {
				action: "we_post_new_comment",
				task_id: $("#task-details-and-comments").data("task-id"),
				new_comment_content: newCommentText,
				pre_new_comment_task_HTML: preNewCommentTaskHtml,
				file: file,
				/* new_client_inquiry_task_name: new_client_inquiry_task_name,
                    new_client_inquiry_task_description:
                        new_client_inquiry_task_description,
                    new_client_inquiry_client_name: new_client_inquiry_client_name,
                    new_client_inquiry_client_company_name:
                        new_client_inquiry_client_company_name,
                    new_client_inquiry_client_email:
                        new_client_inquiry_client_email,
                    new_client_inquiry_client_address_with_files:
                        new_client_inquiry_client_address_with_files,
			}, */
			data: fd,
			success: function (data) {
				var response = JSON.parse(data);
				//$(".tasks-details-column__task-messages").html(response.data);
				$("#new-message-modal").modal("hide");
				$(".comment").removeClass("lastUAddedComment");
				$(response.data).appendTo(".tasks-details-column__task-messages");
				gsap.to(".tasks-details-task-description-and-messages-container", {
					duration: 1,
					scrollTo: { y: "max" },
					onComplete: function () {
						$(".lastUAddedComment").css("opacity", "1");
					},
				});
				//$(".we-loading").remove();
				autoGetActiveTaskDetailsMessages();

				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				console.log("ajax error");
				console.log(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>Dogodila se neka greška. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
	});
}
});
