import { setTasksColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

gsap.registerPlugin(ScrollToPlugin);


$(function () {
	//MOVE TASK TO OTHER SECTION
	$("body").on("click", ".rc-context-menu .move-task", function (e) {
		e.preventDefault();

		var self = $(this);
		var taskToMoveId = $(this)
			.closest(".rc-context-menu")
			.attr("data-selected-task");
		var singleTask = $('.tasks__single[data-id="' + taskToMoveId + '"]');
		var taskDestinationSectionGid = self.data("dest-sect-id");
		var destinationSectionList = singleTask
			.closest(".tasks")
			.find(
				'.tasks__group[data-section-id="' + taskDestinationSectionGid + '"]'
			)
			.find(".tasks__group-tasks-container");

		function removeTaskFromListAddToOtherSection() {
			var movedTaskIndex = singleTask.index();
			singleTask
				.closest(".tasks__group")
				.find(".tasks__single")
				.each(function (index, element) {
					// element == this
					if (index > movedTaskIndex) {
						let taskNumberBeforeMoved = index + 1;
						$(element)
							.find(".tasks__single-we-task-number")
							.text(taskNumberBeforeMoved - 1);
					}
				});
			singleTask.remove();

			var singleTaskCloneForOtherSection = singleTask.clone(true);
			singleTaskCloneForOtherSection.attr(
				"data-task-section",
				$(
					'.tasks__group[data-section-id="' + taskDestinationSectionGid + '"]'
				).attr("data-section-name")
			);

			destinationSectionList
				.find(".tasks__single")
				.each(function (index, element) {
					// element == this
					let taskNumberBeforeMoved = index + 1;
					$(element)
						.find(".tasks__single-we-task-number")
						.text(taskNumberBeforeMoved + 1);
				});

			var singleTaskCloneForOtherSectionWhithoutCommands =
				singleTaskCloneForOtherSection.find(".column-2-commands").remove();

			singleTaskCloneForOtherSectionWhithoutCommands
				.end()
				.prependTo(destinationSectionList);
			singleTaskCloneForOtherSection.css("background-color", "transparent");
			singleTaskCloneForOtherSection
				.find(".tasks__single-we-task-number")
				.text(1);

			//$(".we-loading-in-task").remove();
		} //removeTaskFromListAddToOtherSection

		var moveTaskTimeline = gsap.timeline({
			repeat: 0,
			repeatDelay: 1,
		});

		//add 3 tweens that will play in direct succession.
		moveTaskTimeline.to(singleTask, {
			duration: 0.3,
			backgroundColor: "orange",
			onComplete: removeTaskFromListAddToOtherSection,
		});

		singleTask
			.find(".progress-animation")
			.delay(400)
			.append(
				'<div class="we-loading-in-task" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:transparent" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="#0d47a1" stroke-width="10" r="35" stroke-dasharray="164.934 56.978"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg></div></div>'
			);

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			//processData: true,
			/* contentType: false, */
			dataType: "html",
			//contentType: 'text/html',
			data: {
				action: "move_task_to_other_section",
				task_to_move_id: taskToMoveId,
				task_destination_section_gid: taskDestinationSectionGid,
				security: ajax_object.ajax_nonce,
			},
			//data: fd,
			success: function (data) {
				$(".we-loading-in-task").remove();

				/* $(
						".js-single-project-link[data-id=" + currentProjectID + "]"
					).trigger("click"); */
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>UPS! Nešto je negdje zapelo. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
	});
});
