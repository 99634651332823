import { setTasksColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

gsap.registerPlugin(ScrollToPlugin);


$(function () {
	//DELETE TASK VI RC CUSTOM MENU
	$("body").on("click", ".rc-context-menu .delete-task", function (e) {
		e.preventDefault();
		var currentProjectID = $("#project-details").data("project-id");

		var self = $(this);
		var selectedTaskID = $(this)
			.closest(".rc-context-menu")
			.attr("data-selected-task");

		var selectedTask = $('.tasks__single[data-id="' + selectedTaskID + '"]');

		console.log(selectedTask);

		function removeTaskFromList() {
			var deletedTaskIndex = selectedTask.index();
			selectedTask
				.closest(".tasks__group")
				.find(".tasks__single")
				.each(function (index, element) {
					// element == this
					if (index > deletedTaskIndex) {
						var taskNumberBeforeDeletion = index + 1;
						$(element)
							.find(".tasks__single-we-task-number")
							.text(taskNumberBeforeDeletion - 1);
					}
				});
			selectedTask.remove();
		}

		//removeTaskFromList();

		var deletedTaskTimeline = gsap.timeline({ repeat: 0, repeatDelay: 1 });

		//add 3 tweens that will play in direct succession.
		deletedTaskTimeline.set(selectedTask, {
			color: "#fff",
		});
		deletedTaskTimeline.to(selectedTask, {
			duration: 0.3,
			backgroundColor: "red",
			onComplete: removeTaskFromList,
		});

		selectedTask
			.find(".progress-animation")
			.delay(400)
			.append(
				'<div class="we-loading-in-task" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:transparent" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="#0d47a1" stroke-width="10" r="35" stroke-dasharray="164.934 56.978"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg></div></div>'
			);

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			//processData: true,
			/* contentType: false, */
			dataType: "html",
			//contentType: 'text/html',
			data: {
				action: "delete_task",
				task_to_delete_id: selectedTask.data("id"),
				task_delete_section_gid: selectedTask
					.closest(".tasks")
					.find(".del-section")
					.data("del-section-id"),
				security: ajax_object.ajax_nonce,
			},
			//data: fd,
			success: function (data) {
				//$(".we-loading").remove();

				/* $(
						".js-single-project-link[data-id=" + currentProjectID + "]"
					).trigger("click"); */
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>UPS! Nešto je negdje zapelo. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
	});

	//DELETE TASK VIA TASK DROPDOWN - DEPRECIATED
	$("body").on("click", ".dropdown-item--delete-task", function (e) {
		e.preventDefault();
		var currentProjectID = $("#project-details").data("project-id");

		var self = $(this);
		function removeTaskFromList() {
			var deletedTaskIndex = self.closest(".tasks__single").index();
			self
				.closest(".tasks__group")
				.find(".tasks__single")
				.each(function (index, element) {
					// element == this
					if (index > deletedTaskIndex) {
						var taskNumberBeforeDeletion = index + 1;
						$(element)
							.find(".tasks__single-we-task-number")
							.text(taskNumberBeforeDeletion - 1);
					}
				});
			self.closest(".tasks__single").remove();
		}

		var deleteTaskTimeline = gsap.timeline({ repeat: 0, repeatDelay: 1 });

		//add 3 tweens that will play in direct succession.
		deleteTaskTimeline.set(self.closest(".tasks__single"), {
			color: "#fff",
		});
		deleteTaskTimeline.to(self.closest(".tasks__single"), {
			duration: 0.3,
			backgroundColor: "red",
			onComplete: removeTaskFromList,
		});

		self
			.closest(".tasks__single")
			.find(".progress-animation")
			.delay(400)
			.append(
				'<div class="we-loading-in-task" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:transparent" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><circle cx="50" cy="50" fill="none" stroke="#0d47a1" stroke-width="10" r="35" stroke-dasharray="164.934 56.978"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"/></circle></svg></div></div>'
			);

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			//processData: true,
			/* contentType: false, */
			dataType: "html",
			//contentType: 'text/html',
			data: {
				action: "delete_task",
				task_to_delete_id: self.closest(".tasks__single").data("id"),
				task_delete_section_gid: self
					.closest(".tasks")
					.find(".del-section")
					.data("del-section-id"),
			},
			//data: fd,
			success: function (data) {
				//$(".we-loading").remove();

				/* $(
						".js-single-project-link[data-id=" + currentProjectID + "]"
					).trigger("click"); */
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>UPS! Nešto je negdje zapelo. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
	});
});
