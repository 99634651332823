import "./_hamburgers.scss";
import { gsap } from "gsap";

	//FUNCTIONS
	export function projectCloseColumn() {
		var windowWidt = $(window).width();

		if (windowWidt >= 768) {
			var hideProjectColumnOnLS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			hideProjectColumnOnLS.to(
				".projects__projects-column",
				{
					duration: 0.3,
					marginLeft: -250,
				},
				0
			);
			hideProjectColumnOnLS.to(
				".tasks-column-project-switcher",
				{
					duration: 0.3,
					marginLeft: 0,
				},
				0
			);
			hideProjectColumnOnLS.to(
				".projects__tasks-column-project-details-title-container h3, .projects__tasks-column-project-details-title-container .up-title",
				{
					duration: 0.3,
					marginLeft: "15px",
				},
				0
			);
		} else {
			var hideProjectColumnOnSS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			hideProjectColumnOnSS.to(".projects__projects-column", {
				duration: 0.3,
				left: "-250px",
			});
		}
	}

	export function projectShowColumn() {
		var windowWidt = $(window).width();

		if (windowWidt >= 768) {
			var showProjectColumnOnLS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			showProjectColumnOnLS.to(
				".projects__projects-column",
				{
					duration: 0.3,
					marginLeft: 0,
				},
				0
			);
			showProjectColumnOnLS.to(
				".tasks-column-project-switcher",
				{
					duration: 0.3,
					marginLeft: "-200%",
				},
				0
			);
			showProjectColumnOnLS.to(
				".projects__tasks-column-project-details-title-container h3, .projects__tasks-column-project-details-title-container .up-title",
				{
					duration: 0.3,
					marginLeft: "-35px",
				},
				0
			);
		} else {
			var showProjectColumnOnSS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			showProjectColumnOnSS.to(".projects__projects-column", {
				duration: 0.3,
				left: 0,
			});
		}
	}

$(function () {
	//HAM EVENTS
	$(".project-column-switcher").click(function (e) {
		e.preventDefault();
		projectCloseColumn(); 
	});

	$("body").on("click", ".tasks-column-project-switcher", function (e) {
		e.preventDefault();
		projectShowColumn();
	});

	$("body").on("click", ".task-details-column-switcher", function (e) {
		e.preventDefault();
		var windowWidt = $(window).width();
		var taskDetailsColumnWidth = $(".projects__task-detail-column").width();

		if (windowWidt >= 768) {
			var hideTaskDetailsColumOnLS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			hideTaskDetailsColumOnLS.to(
				".projects__task-detail-column",
				{
					duration: 0.3,
					marginRight: -taskDetailsColumnWidth,
				},
				0
			);
		} else {
			var hideTaskDetailsColumOnSS = gsap.timeline({ repeat: 0, repeatDelay: 1 });

			//add 3 tweens that will play in direct succession.
			hideTaskDetailsColumOnSS.to(".projects__task-detail-column", {
				duration: 0.3,
				right: "-100%",
			});
		}
		tinyMCE.activeEditor.setContent("");
	});
	$("body").on('click', '.tasks__single', function (e) {
		e.preventDefault();
		var windowWidt = $(window).width();

		if (windowWidt >= 768) {
			var showTaskDetailsColumOnLS = gsap.timeline({
				repeat: 0,
				repeatDelay: 1,
			});

			//add 3 tweens that will play in direct succession.
			showTaskDetailsColumOnLS.to(
				".projects__task-detail-column",
				{
					duration: 0.3,
					marginRight: 0,
				},
				0
			);
		} else {
			var hideTaskDetailsColumOnSS = gsap.timeline({
				repeat: 0,
				repeatDelay: 1,
			});

			//add 3 tweens that will play in direct succession.
			hideTaskDetailsColumOnSS.to(".projects__task-detail-column", {
				duration: 0.3,
				right: 0,
			});
		}
	});

	$(window).on("resize", function () {
		if (window.innerWidth >= 992) {
			
			$(".project-column-switcher").removeClass("xfy");
		}
	});
	//END HAM EVENTS
});

import "./ham-triangle-left/ham-triangle-left";
