import "./_right-click-custom-menu.scss";

$(function () {
	if($('.page_upiti').length > 0) {
	const contextMenu = document.getElementById("rc-context-menu-zatrazeno");
	const contextMenuRevizija = document.getElementById("rc-context-menu-revizija");
	//BODY IN PURE JS
	const scope = document.querySelector("body");

	//FOR MENU POSITION NORMALAZATION
	var screenHeight = $(window).height();
	var screenWidth = $(window).width();
	$(window).on("resize", function () {
		screenHeight = $(window).height();
		screenWidth = $(window).width();
	});

	//OPEN WHEN RIGHT CLICKED ON ZATRAŽENO TASKS
	$("body").on("contextmenu", ".tasks__group[data-section-name='Zatraženo'] .tasks__single", function (e) {
		e.preventDefault();

		//
		var self = $(this);
		$(".tasks__single").removeClass("in-focus");
		self.addClass("in-focus");

		const { clientX: mouseX, clientY: mouseY } = event;

		contextMenu.style.left = `${mouseX}px`;

		contextMenu.classList.add("visible");

		$("#rc-context-menu-zatrazeno").attr("data-selected-task", self.data("id"));

		//NORMALIZE THE POSITION OF MENU Y
		let menuHeight = $("#rc-context-menu-zatrazeno").height();

		let mouseYPosition = mouseY;

		if (screenHeight - mouseYPosition < menuHeight) {
			console.log("Mouse should go above");
			contextMenu.style.top = `${mouseY - menuHeight}px`;
		} else {
			contextMenu.style.top = `${mouseY}px`;
		}
		//END NORMALIZE THE POSITION OF MENU Y

		//NORMALIZE THE POSITION OF MENU X
		let menuWidth = $("#rc-context-menu-zatrazeno").width();
		let mouseXPosition = mouseX;

		if (screenWidth - mouseXPosition < menuWidth) {
			console.log("Mouse should go left");
			contextMenu.style.left = `${mouseX - menuWidth}px`;
		} else {
			contextMenu.style.left = `${mouseX}px`;
		}
		//END NORMALIZE THE POSITION OF MENU X
	});

	//OPEN WHEN RIGHT CLICKED ON REVIZIJA TASKS
	$("body").on("contextmenu", ".tasks__group[data-section-name='Revizija'] .tasks__single", function (e) {
		e.preventDefault();

		//
		var self = $(this);
		$(".tasks__single").removeClass("in-focus");
		self.addClass("in-focus");

		const { clientX: mouseX, clientY: mouseY } = event;

		contextMenuRevizija.style.left = `${mouseX}px`;

		contextMenuRevizija.classList.add("visible");

		$("#rc-context-menu-revizija").attr("data-selected-task", self.data("id"));

		//NORMALIZE THE POSITION OF MENU Y
		let menuHeight = $("#rc-context-menu-revizija").height();

		let mouseYPosition = mouseY;

		if (screenHeight - mouseYPosition < menuHeight) {
			console.log("Mouse should go above");
			contextMenuRevizija.style.top = `${mouseY - menuHeight}px`;
		} else {
			contextMenuRevizija.style.top = `${mouseY}px`;
		}
		//END NORMALIZE THE POSITION OF MENU Y

		//NORMALIZE THE POSITION OF MENU X
		let menuWidth = $("#rc-context-menu-revizija").width();
		let mouseXPosition = mouseX;

		if (screenWidth - mouseXPosition < menuWidth) {
			console.log("Mouse should go left");
			contextMenuRevizija.style.left = `${mouseX - menuWidth}px`;
		} else {
			contextMenuRevizija.style.left = `${mouseX}px`;
		}
		//END NORMALIZE THE POSITION OF MENU X
	});

	//REMOVE MENU WHEN CLICKED OUTSIDE IT
	scope.addEventListener("click", (e) => {
		if (e.target.offsetParent != contextMenu) {
				contextMenu.classList.remove("visible");
				//
				$(".tasks__single").removeClass("in-focus");
			
		}
	});
	scope.addEventListener("click", (e) => {
		if (e.target.offsetParent != contextMenuRevizija) {
			contextMenuRevizija.classList.remove("visible");
			//
			$(".tasks__single").removeClass("in-focus");
		}
	});


    //RC CONTEXT MENU ITEM ON CLICK

    $("body").on(
			"click",
			"#rc-context-menu-zatrazeno .rc-context-menu-item, #rc-context-menu-revizija .rc-context-menu-item",
			function (e) {
				e.preventDefault();
				//CLOSE RC CONTEXT MENU
				contextMenu.classList.remove("visible");
				contextMenuRevizija.classList.remove("visible");
			}
		);

	}
});
