import autosize from 'autosize/dist/autosize';

$(function () {
	//AUTOSIZE
	autosize($("textarea"));

	//UPITI PAGE
	//TASKS
    function setTasksColumnWidth() {
        var taskNameColumnsAllWidths = new Array();
        var taskIdColumnsAllWidths = new Array();
        var taskStatusColumnsAllWidths = new Array();
        //TASK NAME
        $(".column--task-2, .column--header-2").each(function (index, element) {
            // element == this
            taskNameColumnsAllWidths.push($(element).outerWidth());
        });
        var taskNameColumnLongestWidth = Math.max(...taskNameColumnsAllWidths);
        //TASK ID
        $(".column--task-1, .column--header-1").each(function (index, element) {
            // element == this
            taskIdColumnsAllWidths.push($(element).outerWidth());
        });
        var taskIdColumnLongestWidth = Math.max(...taskIdColumnsAllWidths);
        //TASK STATUS
        $(".column--task-3, .column--header-3").each(function (index, element) {
            // element == this
            taskStatusColumnsAllWidths.push($(element).outerWidth());
        });
        var taskStatusColumnLongestWidth = Math.max(
            ...taskStatusColumnsAllWidths
        );

        $(".column--header-2, .column--task-2").css(
            "width",
            taskNameColumnLongestWidth + "px"
        );
        $(".column--header-1, .column--task-1").css(
            "width",
            taskIdColumnLongestWidth + "px"
        );
        $(".column--header-3, .column--task-3").css(
            "width",
            taskStatusColumnLongestWidth + "px"
        );
    }

    setTasksColumnWidth();

    $(window).on('resize', function () { 
        setTasksColumnWidth();
    });

    //TASKS - DROPDOWN

    $(".task-commands-dropdown").on("show.bs.dropdown", function () {
			// do something…
            $(this).closest(".tasks__single").addClass('in-focus');
            console.log($(this));
		});

    //SINGLE TASK COLUMN
    
    
});