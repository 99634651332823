import { setProjectsColumnWidth } from "../../inc/we-render-asana-tasks/we-render-asana-tasks";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
import { projectCloseColumn } from "../../components/navigational-icons/panel-switcher/hamburger/hamburger";
import Swal from "sweetalert2";

gsap.registerPlugin(ScrollToPlugin);


$(function () {
	//POST NEW TASK IN PROJECT

	$("#upiti-new-project-form").on("submit", function (e) {
		e.preventDefault();

		var self = $(this);
		var teamGID = self.data("team-id");
		var newProjectName = $("#upiti-new-project-form__title").val();
		var newProjectDescription = $("#upiti-new-project-form__description").val();
		var newProjectFilesAddress = $(
			"#upiti-new-project-form__source-files-address"
		).val();

		$("#new-project-modal").modal("hide");


		function newProjectAdjustments() {

		}

		$(".projects")
			.delay(400)
			.append(
				'<div class="we-loading" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" style="margin:auto;background:0 0" width="200" height="200" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" display="block"><path fill="none" stroke="#0d47a1" stroke-width="6.4" stroke-dasharray="42.765 42.765" d="M19.44 24C9.12 24 4 34.64 4 40s5.12 16 15.44 16c15.44 0 25.68-32 41.12-32C70.88 24 76 34.64 76 40s-5.12 16-15.44 16c-15.44 0-25.68-32-41.12-32z" stroke-linecap="round" style="transform-origin:50px 50px"><animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0;256.58892822265625"/></path></svg></div></div>'
			);

		$.ajax({
			url: "https://zagrebweb.hr/wp-admin/admin-ajax.php",
			type: "POST",
			//processData: true,
			/* contentType: false, */
			dataType: "html",
			//contentType: 'text/html',
			data: {
				action: "post_new_project",
				team_gid: teamGID,
				new_project_name: newProjectName,
				new_project_description: newProjectDescription,
				new_project_files_address: newProjectFilesAddress,
				security: ajax_object.ajax_nonce,
			},
			//data: fd,
			success: function (data) {
				var response = JSON.parse(data);
				console.log(response.data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					timer: 1500,
					icon: "success",
					title: "Projekt uspješno postavljen.",
					text: "",
					showConfirmButton: false,
					html:
						"<p>" +
						"" +
						"</p>" +
						'<div class="sweetalert-btns d-flex flex-column flex-sm-row justify-content-center">' +
						"</div>",
					willClose: newProjectAdjustments,
				});
				var newProjectHtml = `<div class="tasks__single js-new-added-task" data-id="${response.data.data.gid}" style="z-index: 7">
                    <div class="tasks__single-inner d-flex">

                    <div class="tasks__single-we-task-number column column--task column-1 column--task-1" style="width: 51.0469px;">
                    1</div>
                    <div class="tasks__single-we-task-name column column--task column-2 column--task-2 d-flex" style="width: 725px;">

					<div class="column-2-text-notification-icons-container w-100 d-flex">
						<div class="column-2-text">
						${response.data.data.name}
						</div>
						<div class="notification-icons d-flex align-items-center">
							<div class="number-of-messages mr-4"></div>
							<div class="chat-icon">
								<svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
									<path d="M18 54.4h-2.5l1.5-2.1c1.1-1.6 1.9-3.5 2.3-6-7.1-3.2-10.9-9.2-10.9-17.1 0-12.1 9.1-19.6 23.8-19.6C46.9 9.6 56 17.1 56 29.2c0 12.2-8.9 19.6-23.8 19.6h-1c-2.4 2.6-6.4 5.6-13.2 5.6zm14-42.2c-13.3 0-21.2 6.3-21.2 17 0 9.1 5.6 13.2 10.2 15.1l.9.4-.1 1c-.3 2.3-.8 4.3-1.6 6 4.8-.6 7.6-3 9.1-5l.4-.5h2.2c19.1 0 21.2-11.9 21.2-17 .1-10.6-7.8-17-21.1-17z" fill="#134563" />
								</svg>
							</div>
						</div>
					</div>

            <div class="progress-animation">
                    
            </div>

                    <div class="column-2-commands"></div>
                    </div>
                    <div class="tasks__single-we-task-status column column--task column-3 column--task-3" style="width: 110px;">
                        
                    </div>

                    </div>
                    <div class="tasks__single-hover-background"></div>
                    </div>`;

				addNewProjectUpdateSerialNumbers();

				$(newProjectHtml).prependTo(
					'.tasks__group[data-section-name="Zatraženo"] .tasks__group-tasks-container'
				);

				//RESET FORM TESTAREA HEIGHT
				$("textarea").css("height", "auto");

				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				console.log("ajax error");
				console.log(data);
				$(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					showConfirmButton: false,
					html: "<p>Dogodila se neka greška. Pokušajte ponovno ili nas kontaktirajte na email: <a href='mailto:kontakt@zagrebweb.hr'>kontakt@zagrebweb.hr</a> ili broj telefona <a href='tel:00385996434282'>099 643 4282</a></p>",
					//willClose: redirectToProjects,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
		$("#upiti-new-task-form")[0].reset();
	});
});
