import "./_we-render-asana-tasks.scss";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "parsleyjs";
//import Parsley from "parsleyjs";
//import "magnific-popup/dist/jquery.magnific-popup";
//import "magnific-popup/dist/magnific-popup.css";

import "magnific-popup";

//UPITI PAGE
//TASKS
export function setTasksColumnWidth() {
	var taskNameColumnsAllWidths = new Array();
	var taskIdColumnsAllWidths = new Array();
	var taskStatusColumnsAllWidths = new Array();
	//TASK NAME
	$(".column--task-2, .column--header-2").each(function (index, element) {
		// element == this
		taskNameColumnsAllWidths.push($(element).outerWidth());
	});
	var taskNameColumnLongestWidth = Math.max(...taskNameColumnsAllWidths);
	//TASK ID
	$(".column--task-1, .column--header-1").each(function (index, element) {
		// element == this
		taskIdColumnsAllWidths.push($(element).outerWidth());
	});
	var taskIdColumnLongestWidth = Math.max(...taskIdColumnsAllWidths);
	//TASK STATUS
	$(".column--task-3, .column--header-3").each(function (index, element) {
		// element == this
		taskStatusColumnsAllWidths.push($(element).outerWidth());
	});
	var taskStatusColumnLongestWidth = Math.max(...taskStatusColumnsAllWidths);

	$(".column--header-2, .column--task-2").css(
		"width",
		taskNameColumnLongestWidth + "px"
	);
	$(".column--header-1, .column--task-1").css(
		"width",
		taskIdColumnLongestWidth + "px"
	);
	$(".column--header-3, .column--task-3").css(
		"width",
		taskStatusColumnLongestWidth + "px"
	);
}

$(function () {
	//UPITI PAGE
	//TASKS
	setTasksColumnWidth();

	$("body").on("focusout", ".tasks__single", function (e) {
		e.preventDefault();
		$(this).removeClass("in-focus");
	});

	//MESSAGES
	//NEW MESSAGE MODAL OPENER
	$("body").on("click", "#new-message-modal-opener", function (e) {
		e.preventDefault();
		$("#new-message-modal").modal();
	});

	function setMessagesContainerHeight() {
		let textAreaHeight = $(".task-new-message-container").height();
		$(".tasks-details-column__task-description-messages-container").attr(
			'style',
			'height: calc(100vh - 50px - 70px - 0px - '+textAreaHeight+'px);'
		);
	}

	setMessagesContainerHeight();

	setInterval(setMessagesContainerHeight, 500);

	//TASKS
	//NEW TASK MODAL OPENER
	$("body").on("click", "#new-task-modal-opener", function (e) {
		e.preventDefault();
		$("#new-task-modal").modal();
	});

	
	//PROJECTS
	//NEW PROJECT MODAL OPENER
	$("body").on("click", "#new-project-modal-opener", function (e) {
		e.preventDefault();
		$("#new-project-modal").modal();
	});



	//ON RESIZE
	$(window).on("resize", function () {
		setTasksColumnWidth();
	});

	//COMMANDS

	//PARSLEY VALIDATION
	$("#upiti-new-task-form").parsley();
	$("#upiti-edit-task-form").parsley();
	$("#upiti-new-comment-form").parsley();
	Parsley.addMessages("hr", {
		defaultMessage: "Neispravan unos.",
		type: {
			email: "Ovo polje treba sadržavati ispravnu email adresu.",
			url: "Ovo polje treba sadržavati ispravni url.",
			number: "Ovo polje treba sadržavati ispravno upisan broj.",
			integer: "Ovo polje treba sadržavati ispravno upisan cijeli broj.",
			digits: "Ovo polje treba sadržavati znamenke.",
			alphanum: "Ovo polje treba sadržavati brojke ili slova.",
		},
		notblank: "Ovo polje ne smije biti prazno.",
		required: "Ovo polje je obavezno.",
		pattern: "Neispravan unos.",
		min: "Vrijednost treba biti jednaka ili veća od %s.",
		max: "Vrijednost treba biti jednaka ili manja od %s.",
		range: "Vrijednost treba biti između %s i %s.",
		minlength: "Unos je prekratak. Treba sadržavati %s ili više znakova.",
		maxlength: "Unos je predugačak. Treba sadržavati %s ili manje znakova.",
		length:
			"Neispravna duljina unosa. Treba sadržavati između %s i %s znakova.",
		mincheck: "Treba odabrati najmanje %s izbora.",
		maxcheck: "Treba odabrati %s ili manje izbora.",
		check: "Treba odabrati između %s i %s izbora.",
		equalto: "Ova vrijednost treba biti ista.",
	});

	Parsley.setLocale("hr");

	//MAGNIFIC POPUP
	$(document).magnificPopup({
		delegate: ".comment-image-link",
	});

});