//GET PROJECTS
import "./ajax-project-generalni-upiti";
import './ajax-single-project-click';

//TASKS
//GET TASKS
import './ajax-get-single-task-data';
//POST TASK
import "./ajax-post-new-task";
//POST PROJECT
import "./ajax-post-new-project";
//UPDATE TASK
//import "./ajax-update-task"; Goes in version 2
//DELETE TASK
import "./ajax-move-task-to-other-section";
//DELETE TASK
import "./ajax-delete-task";

//COMMENTS
//POST COMMENTS
import './ajax-post-new-comment';

//GOOGLE SHEETS
//import "./ajax-google-sheets";

$(function () {

});